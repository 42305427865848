import { render, staticRenderFns } from "./FilaAtendimentoConsulta.vue?vue&type=template&id=298fb494&"
import script from "./FilaAtendimentoConsulta.vue?vue&type=script&lang=js&"
export * from "./FilaAtendimentoConsulta.vue?vue&type=script&lang=js&"
import style0 from "./FilaAtendimentoConsulta.vue?vue&type=style&index=0&id=298fb494&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports