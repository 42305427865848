<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-md-12 mt-4">
        <v-toolbar style="height: 6rem">
          <v-toolbar-title
            ><v-btn
              @click="consultarAtendimentos"
              color="#1dd1a1"
              class="mr-4 mb-2 mt-6"
            >
              <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
            ></v-toolbar-title
          >

          <v-text-field
            @keyup.enter.native="consultarAtendimentos"
            v-model="consulta"
            class="mt-14"
            label="Pesquise consultas por Data, CPF, especialidade e nome do paciente"
            color="green"
            filled
            solo-inverted
          ></v-text-field>
        </v-toolbar>

        <div class="row mt-6">
          <div
            style="
              display: flex;
              align-itens: center;
              justify-content: space-between;
            "
            class="col-md-12"
          >
            <b-tooltip :target="`tooltip3-target`" triggers="hover">
              Página anterior
            </b-tooltip>
            <b-tooltip :target="`tooltip4-target`" triggers="hover">
              Próxima página
            </b-tooltip>

            <div class="mt-2">
              <h3>Página {{ this.page }} de {{ this.totalPages }}</h3>
            </div>
            <div>
              <v-btn
                :disabled="this.page == 0 || this.page == 1 ? true : false"
                @click="anteriorPage"
                id="tooltip3-target"
                color="#1dd1a1"
                class="mr-1"
              >
                <v-icon center small color="#FFF"
                  >fas fa-arrow-left</v-icon
                ></v-btn
              >

              <v-btn
                :disabled="
                  this.page == this.totalPages || this.totalPages == 0
                    ? true
                    : false
                "
                @click="proximoPage"
                id="tooltip4-target"
                color="#1dd1a1"
              >
                <v-icon center small color="#FFF"
                  >fas fa-arrow-right</v-icon
                ></v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <v-simple-table striped fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="">Paciente</th>
                <th class="">CPF</th>
                <th class="">Especialidade</th>

                <th class="">Prontuário</th>

                <th class="">Opções</th>
              </tr>
            </thead>

            <tbody
              v-for="atendimento in tempAtendimentos"
              :key="atendimento.id"
            >
              <tr>
                <td>{{ atendimento.dados_paciente.pessoa.nome }}</td>
                <td>{{ atendimento.dados_paciente.pessoa.cpf }}</td>
                <td>{{ atendimento.especialidade.descricao }}</td>

                <td>{{ atendimento.dados_prontuario.numero }}</td>

                <td>
                  <router-link
                    v-if="atendimento.dados_prontuario.triagem_existe == true"
                    :to="{
                      name: 'prontuarioPainel',
                      params: {
                        dados: atendimento,
                      },
                    }"
                    v-slot="{ href, navigate }"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn
                        v-b-tooltip.hover.top="'Prontuário'"
                        color="#1dd1a1"
                        class="mr-2 mb-2 mt-2"
                      >
                        <v-icon center small color="#FFF"
                          >fas fa-book-medical</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>

                  <router-link
                    v-if="atendimento.dados_prontuario.triagem_existe == false"
                    :to="{
                      name: 'novaTriagem',
                      params: {
                        id: atendimento.paciente_id,
                        id2: atendimento.id,
                        id3: atendimento.especialidade_id,
                        id4: atendimento.dados_prontuario.id,
                      },
                    }"
                    v-slot="{ href, navigate }"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <v-btn
                        v-b-tooltip.hover.top="'Realizar nova triagem'"
                        color="#1dd1a1"
                        class="mr-2 mb-2 mt-2"
                      >
                        <v-icon center small color="#FFF"
                          >fas fa-file-alt</v-icon
                        ></v-btn
                      >
                    </a>
                  </router-link>

                  <v-btn
                    v-else
                    disabled
                    v-b-tooltip.hover.top="'Realizar nova triagem'"
                    color="#1dd1a1"
                    class="mr-2 mb-2 mt-2"
                  >
                    <v-icon center small color="#FFF"
                      >fas fa-file-alt</v-icon
                    ></v-btn
                  >

                  <v-btn
                    @click="finalizarConsulta(atendimento.id)"
                    v-b-tooltip.hover.top="'Finalizar consulta'"
                    color="#1dd1a1"
                    class="mr-2 mb-2 mt-2"
                  >
                    <v-icon center small color="#FFF"
                      >fas fa-check-square</v-icon
                    ></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div
          v-if="tempAtendimentos.length == 0"
          class="d-flex flex-column-fluid flex-center mt-12"
        >
          <a href="#" class="text-center mb-10">
            <img src="media/novaImagens/logo.png" class="max-h-200px" alt="" />
          </a>
        </div>
      </div>
    </div>

    <!--end::Dashboard-->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";

export default {
  name: "FilaDeAtendimentoConsulta",
  async created() {
    try {
      const response = await ambulatorio.listEncaminhamentosPaged(1, 3);
      this.totalPages = response.data.total_pages;
      this.atendimentos = response.data.encaminhamentos;
      this.tempAtendimentos = response.data.encaminhamentos;
      console.log("informações consulta");
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      totalPages: 1,
      page: 1,
      atendimentos: [],
      tempAtendimentos: [],
      consulta: "",
    };
  },

  methods: {
    finalizarConsulta: async function (idEncaminhamento) {
      try {
        await ambulatorio.finalizarEncaminhamentoConsulta(idEncaminhamento);

        const response = await ambulatorio.listEncaminhamentosPaged(1, 3);
        this.totalPages = response.data.total_pages;
        this.atendimentos = response.data.encaminhamentos;
        this.tempAtendimentos = response.data.encaminhamentos;

        Swal.fire({
          title: "",
          text: "Consulta finalizada com sucesso!",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar finaliação, verifique dados e procedimentos",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },
    consultarAtendimentos: async function () {
      try {
        if (this.consulta == "" || this.consulta == " ") {
          const response = await ambulatorio.listEncaminhamentosPaged(1, 3);
          this.atendimentos = response.data.encaminhamentos;
          this.tempAtendimentos = response.data.encaminhamentos;
        } else {
          const data = {
            filter: {
              nome: this.consulta,
              nome_social: this.consulta,
              cpf: this.consulta,
              "especialidades.descricao": this.consulta,
            },
            page: 1,
            size: 10,
          };

          const resultBusca = await ambulatorio.buscaEncaminhamentos(data);

          this.atendimentos = resultBusca.data.resultado;
          this.tempAtendimentos = resultBusca.data.resultado;
        }
      } catch (error) {
        console.log(error);
      }
    },
    proximoPage: async function () {
      try {
        this.page++;
        if (this.page <= this.totalPages) {
          const response = await ambulatorio.listEncaminhamentosPaged(
            this.page,
            3
          );
          this.atendimentos = response.data.encaminhamentos;
          this.tempAtendimentos = response.data.encaminhamentos;
        } else {
          this.page--;
        }
      } catch (error) {
        console.log(error);
      }
    },
    anteriorPage: async function () {
      try {
        this.page--;
        if (this.page >= 1) {
          const response = await ambulatorio.listEncaminhamentosPaged(
            this.page,
            3
          );
          this.atendimentos = response.data.encaminhamentos;
          this.tempAtendimentos = response.data.encaminhamentos;
        } else {
          this.page++;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Fila de atendimento para consulta " },
    ]);
  },
};
</script>

<style>
.textStyle {
  color: white;
  font-size: 1.2em;
}
</style>
